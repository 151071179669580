body {
  display: block;
  width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --safe-width:1200px;
  --space-16:16px;
  --space-32:32px;
}

body::-webkit-scrollbar{/*滚动条整体样式*/
  width: 0PX;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 0PX;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
