.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --space-16:16px;
    --space-32:32px;
    background: #F4F7F9;
    --covert-width: calc((1200px - 32px) / 2);
}

/**上部大背景**/
.pageContainerTopBackground{
    position: absolute;
    z-index: -100;
    width: 100vw;
    height: 600px;
}

/*Events*/
.eventContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: 10px auto 80px;
}
.eventBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
    margin-top: 64px;
}
.eventBigTitle{
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
}
.eventBigMore{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}

/*第一Event*/
.firstEvent{
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}
.firstEventImg,.firstEventImg>img{
    display: block;
    width: 100%;
    position: relative;
    height: 375px
}
/*整行Event*/
.moreEvent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
    margin-top: 16px;
}
.eventBox{
    text-decoration: none;
}


.eventBoxImg,.eventBoxImg>img{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    height: calc(var(--covert-width) * 9 / 16 );
}

.eventStatusBox{
    display: inline-block;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    bottom: 0;
}

.eventStatusActive{
    background: #F6866F;
}

.eventStatusClose{
    background: rgba(94, 98, 102, 0.4);
    backdrop-filter: blur(20px);
}

/*标题时间*/
.eventTitleContainer{
    display: flex;
    flex-direction: column;
    padding: 18px 24px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.eventTitle{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    margin-bottom: 8px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
}
.eventDateTime{
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #C0C6CC;
}

/**加载更加**/
.loadMore{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 369px;
    border: 1px solid #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: auto auto 80px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.loadMore:hover{
    background: #000000;
    color: #FFFFFF;
    transition: all 0.3s;
}
@media only screen and (max-width: 1200px){
    .eventContainer{
        width: calc(100% - 64px);
        --covert-width: calc(100vw / 2 - 32px);
    }
    .firstEventImg,.firstEventImg>img{
        height: calc(100vw * 375 / 1200);
    }
}

@media only screen and (max-width: 768px){
    /*Event*/
    .eventContainer{
        width: calc(100% - 32px);
        margin: 0 auto 32px;
        --covert-width: calc(100vw - 32px);
    }
    .pageContainerTopBackground{
        z-index: 100;
        pointer-events:none;
    }
    .eventBigTitleContainer{
        /*padding-left: var(--space-16);*/
        /*padding-right: var(--space-16);*/
        margin-bottom: 16px;
        margin-top: 32px;
    }
    .eventBigTitle{
        font-size: 32px;
    }
    .eventBigMore{
        font-size: 16px;
    }
    .firstEvent{
        display: none;
        width: auto;
        padding-left: var(--space-16);
        padding-right: var(--space-16);
    }


    .moreEvent{
        display: flex;
        flex-direction: column;
    }
    .eventBox{
        width: 100%;
    }

    .loadMore{
        width: calc(100% - 80px);
        padding: 20px;
        background: #000000;
        border: 1px solid #2F3133;
        color: #FFFFFF;
        margin: auto auto 32px;
    }
}