.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFFFFF;
    overflow: hidden;
    margin: 0 auto;
}

/*banner小广告栏*/
.adBannerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F6866F;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    padding: 12px 24px;
    text-align: center;
}
.adBanner{
    color: #FFFFFF;
}
.arrow{
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
}

.selection1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 88px;
    width: 100%;
    max-width: var(--safe-width);
    margin: 0 auto;
}

.selection1Left{/**LOGO区域**/
    display: flex;
    width: 30%;
    justify-content: flex-start;
    align-items: center;
}
.selection1Left svg{
    height: 28px;
    cursor: pointer;
}
.divide{
    margin-left: 20px;
    margin-right: 20px;
    /*opacity: 0.4;*/
    /*border: 1px solid #8D9399;*/
    /*height: 24px;*/
}
.selection1Right{/**菜单头像区域**/
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
    align-items: center;
    width: 70%;
    justify-content: flex-end;
}
.selection1RightItem{
    text-decoration: none;
    color: #2F3133;
    margin-right: 40px;
    cursor: pointer;
}
.active{
    color: #F6866F !important;
}

.avatar{/**头像**/
    display: flex;
}
.avatar>img{
    height: 48px;
    width: 48px;
    border-radius: 24px;
}

.drawerBtn{/**抽屉按钮**/
    display: none;
    cursor: pointer;
}

.selection2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F6866F;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    padding-top: 12px;
    padding-bottom: 12px;
}
.adBanner{
    color: #FFFFFF;
}
.arrow{
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
}

/*抽屉菜单遮罩*/
.drawerOutContainer{
    background: #000000;
    opacity: 0.8;
    display: none;
}
.drawerOutContainerOpen{
    display: flex;
    transition: all 0.3s;
    width: 100%;
    height: 100vh;
}

.drawerContainer{
    height: 1px;
    overflow-y: hidden;
    display:none;
}

/**抽屉按钮+动效**/
.navStatusButton{
    stroke: black;
    stroke-width: 2px;
    transition: all 0.5s;
}
.hamMedium {
    stroke-dasharray: 16 16;
    stroke-dashoffset: 5;
    transform-origin: center center;
    transition: all 0.5s 0.1s;
}
.hamTop,
.hamBottom {
    stroke-dasharray: 42 42;
    stroke-dashoffset: 26.2;
    transition: all 0.5s 0.1s;
}
.navStatusButtonHover{
    transform: rotate(45deg);
}
.hamMediumHover{
    stroke-dashoffset: 0;
    transform: rotate(90deg);
}
.hamTopHover{
    stroke-dashoffset: -26.8;
}

/**移动端**/
@media only screen and (max-width: 1200px){
    .container{
        width: 100%;
    }
    /*主导航菜单*/
    .selection1{
        height: 56px;
        justify-content: space-between;
    }
    /**LOGO区域**/
    .selection1Left{
        justify-content: space-between;
        width: auto;
        padding-left: 16px;
    }
    .selection1Left img{
        height: 26px;
        cursor: pointer;
    }
    .selection1Left .divide img{
        height: 16px;
        width: 2px;
    }
    .divide{
        margin-left: 12px;
        margin-right: 12px;
        height: 16px;
    }
    .selection1Right{
        width: auto;
        padding-right: 16px;
    }
    .selection1RightItem{
        display: none;
    }
    .avatar,.drawerBtn{
        display: flex;
    }
    .avatar>img{
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        margin-right: 20px;
    }
    .drawerBtn>img{
        height: 14px;
    }


    /*小广告栏*/
    .selection2{
        padding: 12px 24px;
        text-align: center;
    }

    /**抽屉菜单容器**/
    .drawerContainer{
        flex-direction: column;
        align-items: flex-start;
        /*gap: 16px;*/
        background: #FFFFFF;
        position: absolute;
        right: 0px;
        top: 56px;
        z-index: 10;
        overflow-y: scroll;
    }

    .drawerContainer::-webkit-scrollbar{/*滚动条整体样式*/
        width: 1px;     /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }

    .drawerContainerOpen{
        display: flex;
        padding: 32px;
        animation: drawerContainerOpen 300ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards !important;
    }
    .drawerContainerClose{
        display: flex;
        animation: drawerContainerClose 300ms;
        animation-iteration-count: 1;
        animation-fill-mode: forwards !important;
    }
    @keyframes drawerContainerOpen {
        from{
            height: 0px;
            width: 0px;
            opacity: 0;
        }
        to{
            height: calc(100vh - 56px - 64px);
            width: 66vw;
            opacity: 1;
        }
    }
    @keyframes drawerContainerClose {
        from{
            height: calc(100vh - 56px - 64px);
            width: 66vw;
            opacity: 1;
        }
        to{
            height: 0px;
            width: 0px;
            opacity: 0;
        }
    }
    .drawerBtnOpen{
        animation-iteration-count: 1;
        animation-fill-mode: forwards !important;
    }
    @keyframes drawerBtnOpen {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(90deg);
        }
    }
    .drawerBtnClose{
        animation-iteration-count: 1;
        animation-fill-mode: forwards !important;
    }
    @keyframes drawerBtnClose {
        from{
            transform: rotate(90deg);
        }
        to{
            transform: rotate(0deg);
        }
    }
    .drawerTitle{
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: 700;
        font-size: 20px;
        line-height: 130%;
        color: #2F3133;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 16px;
    }
    .drawerItem{
        font-weight: 700;
        font-size: 20px;
        color: #2F3133;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 32px;
    }
    .drawerArrow{
        font-size: 32px;
        font-weight: 800;
    }
    .drawerTitle svg{
        fill: currentColor;
    }
    .drawerTitle:hover,.drawerItem:hover{
        color: #F6866F !important;
    }

    /*  媒体图标  */
    .socialContainer{
        display: flex;
        width: 100%;
        justify-content: space-between;
        bottom: 64px;
        background: #FFFFFF;
        /*position: absolute;*/
        /*bottom: 32px;*/
    }
    .socialContainer a{
        text-decoration: none;
        color: #000000;
    }
    .socialContainer img,.socialContainer svg{
        width: 20px;
        height: 20px;
        fill: currentColor;
    }
}