.backgroundContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    background: #000000;
    padding-top: 80px;
    padding-bottom: 80px;
}

.container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: var(--safe-width);
    color: #FFFFFF;
    margin:0 auto;
}



/*左边订阅*/
.selectionLeft{
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 200px;
    padding-left: 16px;
}

.logoContainer{
    display: flex;
    align-items: center;
}

.divide{
    margin-left: 18px;
    margin-right: 18px;
    opacity: 0.4;
    border: 1px solid #8D9399;
    height: 24px;
}

.subscribeContainer{
    
}
/*订阅表单*/
.subscribeContainer{
    display: flex;
    flex-direction: column;
}
.subscribeHeader{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 50px;
    margin-bottom: 32px;
}
.subscribeForm{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    border-radius: 4px;
    height: 48px;
    box-sizing: border-box;
    transition: all 0.3s;
    border: 2px solid #d2d2d200;
    width: 90%;
    max-width: 450px;
}
.subscribeForm.suc {
    pointer-events: none;
    justify-content: center;
    align-items: center;
    background: rgba(93, 194, 100, 0.12);
    color: #5DC264;
}

.subscribeForm.fail {
    border: solid 2px #F6866F;
    background: #F6866F;
}

.subscribeInput{
    box-sizing: border-box;
    width: 100%;
    padding-left: 24px;
    border: none;
    outline: none;
    background-color: #FFFFFF;
    transition: all 0.3s;
    border-radius: 2px;
    font-size: 14px;
    -webkit-appearance:none;/*去除阴影边框*/
}
.subscribeInput:hover{
    background-color: #F1F3F6;
}
.subscribeInput.active {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 0px 2px #e8f0f5;
}
.subscribeButton{
    height: auto;
    line-height: normal;
    padding: 0px;
    background: none !important;
    border: #FFFFFF !important;
    outline: none;
    width: 72px;
    cursor: pointer;
}
.subscribeIcon {
    stroke: rgba(62, 63, 70, 0.6);
}

.subscribeIcon.suc {
    stroke: green;
}
.subscribeButton img{
    vertical-align: middle;
    width: 24px !important;
}

.subscribeFormErrorTips{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    color: #F93920;
    align-items:center;
    margin-top: 16px;
    visibility: hidden;
    min-height: 20px;
}
.subscribeFormErrorTips>img{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

/*  媒体图标  */
.socialContainer{
    display: flex;
    margin-top: 32px;
    color: #FFFFFF !important;
}
.socialContainer a{
    text-decoration: none;
    color: #FFFFFF;
}
.socialContainer img,.socialContainer svg{
    width: 20px;
    height: 20px;
    margin-right: 34px;
    fill: currentColor;
}


/*右边菜单*/
.selectionRight{
    display: flex;
    width: 50%;
    min-height: 200px;
    justify-content: space-around;
    padding-right: 16px;
}

.navContainer{
    display: flex;
    flex-direction: column;
}

.navTitle{
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 32px;
}
.navItem{
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 32px;
}


/*版权信息*/
.selectionCopyRight{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    margin: 0 auto;
    padding-left: 16px;
}

.copyRightInfo{
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    margin-top: 6px;
    text-decoration: none;
}

.copyRightInfo a{
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}

.divide2{
    margin-left: 18px;
    margin-right: 18px;
    font-weight: 600;
    opacity: 0.4;
    color: #8D9399;
}

/**移动端**/
@media only screen and (max-width: 768px){
    .logoContainer{
        justify-content: center;
    }
    .backgroundContainer{
        padding-top: 55px;
        padding-bottom: 55px;
    }
    .container{
        flex-direction: column;
        justify-content: space-between;
        padding: 0px 16px;
        width: auto;
    }
    .selectionLeft{
        width: 100%;
        padding-left: 0;
    }
    .subscribeForm{
        width: 100%;
    }
    .socialContainer{
        justify-content: space-evenly;
        margin-bottom: 16px;
        align-items: center;
        width: 100%;
        display: flex;
    }
    .socialContainer svg{
        margin-right: 0;
    }
    .selectionCopyRight{
        display: block;
        padding: 0px 16px;
        text-align: center;
        width: auto;
    }
    .copyRightInfo{
        margin-top: 0;
        margin-bottom: 16px;
    }
    .divide2{
        display: inline-block;
    }
    .divide2:first-child{
        display: none !important;
    }
    .copyRightInfo:nth-child(2){
        display: none;
    }

    .selectionRight{
        display: none;
    }

}