.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    background: #F4F7F9;
}

.pageContainer p,span{
    font-size: 16px !important;
}

/**顶部大标题**/
.topContainer{
    display: flex;
    padding-top:64px;
    padding-bottom: 64px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.topText{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    height: 62px;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
}

/**通用段落卡片容器**/
.cardContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    justify-content: center;
    /*background: #FFFFFF;*/
    flex-direction: column;
    margin: 24px auto 64px;
}
/**通用段落卡片灰色背景**/
.cardContainerBgGrey{
    background: #F4F7F9;
}

@media only screen and (max-width: 1200px){
    /**顶部大标题**/
    .topContainer {
        padding-top:32px;
        padding-bottom: 32px;
        padding-left: 32px;
    }
    .cardContainer{
        width: calc(100% - 64px);
        margin: auto auto 64px;
    }
}

@media only screen and (max-width: 768px){
    /**顶部大标题**/
    .topContainer {
        padding-top:32px;
        padding-bottom: 32px;
        padding-left: 16px;
        /*background: #F1F3F6;*/
    }

    .topText{
        font-size: 32px;
    }

    /**通用段落卡片容器**/
    .cardContainer{
        width: calc(100vw - 48px);
        margin: auto;
        padding: 24px;
    }
}