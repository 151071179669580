.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --cardItem-width:256px;
    --cardItem-height:144px;
    background: #F4F7F9;
    --covert-width: calc((1200px - 48px) / 4);
}

/**上部大背景**/
.pageContainerTopBackground{
    position: absolute;
    z-index: -100;
    width: 100vw;
    height: 600px;
}

/*CreatorsBlogs容器*/
.creatorBlogsContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: 10px auto 80px;
}

/*顶部大标题*/
.creatorBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.creatorBigTitle{
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #2F3133;
}

/**用户信息容器**/
.creatorInfoContainer{
    width:100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /*gap: 8px;*/
    margin: 40px auto 80px;
}

/*用户头像*/
.creatorAvatar{
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

/*用户昵称*/
.creatorNickname{
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #2F3133;
    align-items: center;
    /*gap: 6px;*/
    /*margin: 12px auto 8px;*/
    margin-top: 16px;
}
.creatorNickname>img{
    height: 20px;
    width: 20px;
    margin-left: 6px;
}

/*用户标签*/
.creatorTagsContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}
.creatorTags{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid #C0C6CC;
    font-size: 12px;
}

/*用户简介*/
.creatorDesc{
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #2F3133;
    /*margin-bottom: 22px;*/
}

/*用户所在国家地区*/
.creatorCountry{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    /*line-height: 130%;*/
    text-align: center;
    color: #8D9399;
    align-items: center;
    margin: 16px auto;
}
.creatorCountry>img{
    width: 14px;
    height: 14px;
    margin-right: 4px;
    margin-left: 18px;
}

.creatorCountry>a{
    width: 126px;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-decoration-line: underline;
    color: #8D9399;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*用户社交*/
.creatorSocial{
    display: flex;
    align-items: center;
    gap: 6px;
}
.creatorSocial a{
    cursor: pointer;
    text-decoration: none;
}
.creatorSocial a img{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

/*用户作品列表容器*/
.worksListContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
}

.worksListItem{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 24px;
    cursor: pointer;
    text-decoration: none;
}
.worksPoster{
    width: 100%;
    height: calc(var(--covert-width) * 9 / 16 );
}
.worksDeviceAndTitleContainer{
    display: flex;
    flex-direction: column;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    padding: 0 16px;
}
.worksDeviceContainer{
    display: flex;
    gap: 6px;
    margin-top: 16px;
    margin-bottom: 8px;
}
.worksDevice{
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #2F3133;
    border: 1px solid #C0C6CC;
    padding:3px 8px 2px;
}
.worksTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
}

/**加载更加**/
.loadMore{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 369px;
    border: 1px solid #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: auto auto 80px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.loadMore:hover{
    background: #000000;
    color: #FFFFFF;
    transition: all 0.3s;
}

@media only screen and (max-width: 1200px){
    .creatorBlogsContainer{
        width: calc(100% - 48px);
        padding: 0 24px;
        --covert-width: calc((100vw - 16px) / 2);
    }
    /*用户作品列表容器*/
    .worksListContainer{
        grid-template-columns: 1fr 1fr;
    }
    .worksListItem{
        width: 100%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px){
    .creatorBlogsContainer{
        width: 100%;
        padding: 0;
    }

    .pageContainerTopBackground{
        z-index: 100;
        pointer-events:none;
    }
    /*CreatorsBlogs容器*/
    .creatorBlogsContainer{
        display: flex;
        width: 100%;
        max-width: var(--safe-width);
        flex-direction: column;
        margin: auto;
    }
    .creatorBigTitleContainer{
        margin: 0 24px;
    }
    .creatorBigTitle{
        font-size: 32px;
    }

    /*用户信息区域*/
    .creatorInfoContainer{
        width: calc(100% - 48px);
        margin: 32px auto;
        padding: 0 24px;
    }

    /*用户头像*/
    .creatorAvatar{
        width: 96px;
        height: 96px;
        border-radius: 48px;
    }

    /*用户作品列表容器*/
    .worksListContainer{
        width: calc(100% - 48px);
        margin: 0 24px 32px;
        justify-content: space-between;
        grid-gap: 8px;
    }

    .worksDeviceAndTitleContainer{
        padding: 0 8px;
    }

    .worksDevice{
        font-size: 12px;
    }

    .worksTitle{
        font-size: 14px;
    }

    /**加载更加**/
    .loadMore{
        width: calc(100% - 72px);
        padding: 20px;
        background: #000000;
        border: 1px solid #2F3133;
        color: #FFFFFF;
        margin: auto auto 32px;
    }
}