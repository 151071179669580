.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --space-16:16px;
    --space-32:32px;
    --video-height:800px;
    --covert-width: calc((1200px - 32px) / 3);
}
.coverShade{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;
}
/**轮播广告容器**/
.wheelBannerContainer{
    width: 100%;
    background: #F4F7F9;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bannerCarousel{
    width: 100%;
    height: var(--video-height)
}
.bannerBigTitle{
    width: 100%;
    max-width: var(--safe-width);
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #FFFFFF;
    margin: 80px auto 12px;
}
.bannerContentContainer{
    width: 100%;
    max-width: var(--safe-width);
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.bannerContent{
    width: 100%;
    margin-bottom: 80px;
}
.bannerContentText{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #FFFFFF;
}
.bannerContentTextMTitle{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #2F3133;
}
.bannerContentTextM{/**移动端**/
    display: none;
    width: calc(100% - 80px);
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    margin: 0 16px;
    gap: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    background: linear-gradient(90deg, #E7EEF6 0%, rgba(231, 238, 246, 0) 100%);
    transform: translateY(-18px);
}
.bannerImgBackground{
    text-decoration:none;
    cursor:pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.bannerVideo{
    width: 100%;
    /*height: 100%;*/
    object-fit: cover;
    aspect-ratio: 1920 / 800;
}

/*Featured Works*/
.featuredContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: auto auto 64px;
}
.featuredBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.featuredBigTitle{
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    color: #000000;
}
.featuredBigMore{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
.featuredBigMore>svg{
    margin-left: 5px;
}
.featuredBigMore2{
    align-items: center;
    display: none;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
/*整行Featured*/
.moreFeatured{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: var(--space-16);
}
.moreFeatured{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}
.featuredBox{
    text-decoration: none;
    cursor: pointer;
}
.featuredBoxImg,.featuredBoxImg>a>img{
    display: block;
    width: 100%;
    position: relative;
    height: calc(var(--covert-width) * 9 / 16 );
}
.featuredBox:last-child{
    margin-right: unset;
}
.featuredBoxInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 2;
}
.featuredNickRow{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
    text-decoration: none;
    pointer-events: fill;
}
.featuredNick{
    width: var(--space-32);
    height:  var(--space-32);
    border-radius: var(--space-16);
    margin-right: 12px;
}
.featuredNickname{
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}
.featuredTitleContainer{
    display: flex;
    flex-direction: column;
}
.featuredProductName{
    display: inline-block;
    margin: 12px 16px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
.featuredTitle{
    display: block;
    padding: 0 16px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

/**加载更加**/
.loadMore{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 369px;
    border: 1px solid #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: auto auto 80px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.loadMore:hover{
    background: #000000;
    color: #FFFFFF;
    transition: all 0.3s;
}
@media only screen and (max-width: 1200px) {
    .pageContainer {
        --covert-width: calc((100vw - 32px) / 2);
    }
    .moreFeatured{
        grid-template-columns: 1fr 1fr;
    }
    .bannerContentContainer{
        width: calc(100% - 64px)
    }
    .bannerContent{
        margin-bottom: 40px;
    }
    .featuredContainer{
        width: calc(100% - 64px);
        margin: auto;
        margin-bottom: 64px;
    }
}
@media only screen and (max-width: 768px){
    .pageContainer {
        --video-height:375px;
        width: 100%;
        padding-right: unset;
        padding-left: unset;
        --covert-width: calc(100vw - 32px);
    }
    /**轮播广告容器**/
    .wheelBannerContainer{
        --video-height:375px;
        width: 100%;
        max-height: unset;
        height: auto;
        overflow: hidden;
        background:#FFFFFF;
        margin-bottom: 16px;
    }
    .wheelBannerContainer > .coverShade{
        display: none;
    }
    .bannerCarousel{
        width: 100%;
        height: var(--video-height)
    }
    .bannerVideo{
        height: var(--video-height);
    }
    .bannerBigTitle{
        margin: 20px auto auto;
        padding-left: 24px;
        font-weight: 700;
        font-size: 32px;
        line-height: 130%;
    }
    .bannerContentContainer{
        display: none;
        width: calc(100% - 48px);
        margin: 150px auto auto;
        padding: 24px;
        position: absolute;
        bottom: 30px;
        left: unset;
    }
    .bannerContent{
        width: 100%;
    }
    .bannerContentText{
        font-weight: 600;
        font-size: 16px;
    }
    .bannerContentTextM { /**移动端**/
        display: flex;
    }

    /*Featured*/
    .featuredContainer{
        width: calc(100% - 32px);
        margin: auto auto 48px;
    }

    .featuredBigTitleContainer{
        margin-bottom:0;
    }
    .featuredBigTitle{
        font-size: 24px;
    }
    .featuredBigMore{
        font-size: 16px;
        display: none;
    }
    .featuredBigMore2{
        display: flex;
    }
    .moreFeatured{
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        grid-gap:0;
    }
    .featuredBox{
        width: 100%;
        margin-bottom: 16px;
    }


    /**加载更加**/
    .loadMore{
        width: calc(100% - 80px);
        padding: 20px;
        background: #000000;
        border: 1px solid #2F3133;
        color: #FFFFFF;
        margin: auto auto 32px;
    }
}