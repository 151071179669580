.pageContainer{
    display: block;
    width: 100%;
    /*overflow-x: inherit;!***影响导航栏浮动***!*/
    margin: 0 auto;
}

.navBar{
    width: 100%;
    position: sticky;
    z-index: 100;
    top: 0;
}

.navBarFloat{
    position: sticky;
    top: 1px;
    z-index: 10;
}

.banner{
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.content{
    width: 100%;
    min-height: 300px;
}

@media only screen and (max-width: 768px){
    .banner{
        background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/banner.jpg");
        background-position-x: center;
    }
}