.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --space-16:16px;
    --space-32:32px;
    --covert-width: calc((1200px - 32px) / 3);
}
.coverShade{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;
}
/*banner小广告栏*/
.adBannerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #000000;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    position: fixed;
    top: 88px;
    z-index: 1;
}
.adBanner{
    color: #FFFFFF;
}
.arrow{
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
}

/*Events*/
.eventContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: 64px auto;
}

/*第一Event*/
.firstEvent{
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}
.firstEventImg,.firstEventImg>img{
    display: block;
    width: 100%;
    height: 375px;
    position: relative;
}
.eventStatusBox{
    display: inline-block;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    bottom: 0;
}
.eventStatusActive{
    background: #F6866F;
}
.eventStatusClose{
    background: rgba(94, 98, 102, 0.4);
    backdrop-filter: blur(20px);
}

/**文本信息容器**/
.infoContainer{
    display: flex;
    width: calc(100% - var(--space-16));
    max-width: var(--safe-width);
    padding-left: var(--space-16);
    padding-right: var(--space-16);
    margin: 64px auto;
}

/**左边申请人数**/
.infoText{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 120px;
}

/**项目标题**/
.infoTextTitle{
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
    color: #2F3133;
    overflow-wrap: break-word;
}

/**项目申请人数**/
.infoTextApply{
    margin-top: 64px;
    display: flex;
    align-items: center;
}
.infoTextApplyRow{
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}
.infoTextApply .text{
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
}
.vLine{
    width: 1px;
    height: 24px;
    background: #ECEEF1;
}
.infoTextApply .nums{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #2F3133;
}

/**右边时间轴**/
.infoTimeline{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.rowLine{
    display: flex;
    align-items: baseline;
    margin-top: 16px;
}
.rowLineBigTitle{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #2F3133;
}
.dotOrange{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #F6866F;
}
.dotBlue{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #6FB3F6;
}
.dotGreen{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #8DD258;
}
.dotPurple{
     display: inline-block;
     width: 10px;
     height: 10px;
     border-radius: 5px;
     background: #B39BF7;
 }
.rowLineTitle{
    margin-left: 16px;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
}
.rowLine2{
    margin-top: 16px;
    margin-left: 4px;
    border-left: solid 1px #ECEEF1;
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
}

/**详情容器**/
.detailContainer{
    clear: both;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 0;
}

.detailContainer *{
    width: 100%;
    max-width: var(--safe-width);
    background: unset !important;
}

/**申请按钮**/
.btnApply{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 384px;
    background: #F6866F;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    cursor: pointer;
    margin: 64px auto;
}

/*背景遮罩*/
.applyFormOutContainer{
    display: none;
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}
/**申请表单容器**/
.applyFormContainer{
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    position: fixed;
    width: 840px;
    left: calc(50% - 840px/2);
    top: calc(50% - 568px/2);
    background:#FFFFFF;
    pointer-events: fill;
    z-index: 101;
}
.applyFormShow{
    display: flex;
}
/*表单标题*/
.applyFormTitleContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.applyFormTitleText{
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #2F3133;
}
.applyFormClose{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
/*表单行*/
.applyFormRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
}
/*表单列*/
.applyFormColumn{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
}
/*列标题*/
.applyFormColumnTitle{
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
}
/*列输入框*/
.applyFormColumnInput{
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    margin: 0;
    padding: 6px 11px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #DCE1E6;
    border-radius: 2px;
    transition: all 0.3s;
    font-size: 16px;
}
.applyFormColumnInput:focus{
    border-color: #ffaf9c;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(246 134 111 / 20%);
}

.applyFormColumnInput2{
    width: 100%;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2F3133;
    gap: 24px;
}
.applyFormColumnInput2 label{
    display: flex;
    align-items: center;
}
.applyFormColumnInput2 input{
    width: 18px;
    height: 18px;
    margin-top: 0;
}
.applyFormColumnInput2 input:checked:before{
    content: '';
    width: 16px;
    height: 16px;
    border: 1px solid #DCE1E6;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-top: 0;
    margin-left: 0;
}
.applyFormColumnInput2 input:checked:after{
    content: '';
    width: 10px;
    height: 10px;
    background:#F6866F;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: absolute;
    margin-top: 4px;
    margin-left: -14px;
}
/**表单提交按钮**/
.applyFormSubmitBtn{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: #F6866F;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    margin: auto;
    cursor: pointer;
}
.applyFormSubmitBtnDisable{
    display: none;
}
/**错误提示**/
.applyFormTipsRow{
    width: 100%;
    display: none;
    flex-direction: row;
}
.applyFormTipsError{
    display: flex;
    color: red;
    font-size: 12px;
    margin-top: 16px;
}
.applyFormTipsSuccess{
    display: flex;
    color: green;
    font-size: 12px;
}


/*用户回片 Featured Works*/
.featuredContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: 34px auto;
}
.featuredBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding-left: 8px;
    padding-right: 8px;
}
.featuredBigTitle{
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    color: #000000;
}
.featuredBigMore{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
.featuredBigMore2{
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
/*整行Featured*/
.moreFeatured{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}
.featuredBox{
    margin-bottom: 16px;
    text-decoration: none;
    cursor: pointer;
}
.featuredBoxImg,.featuredBoxImg>a>img{
    display: block;
    width: 100%;
    position: relative;
    height: calc(var(--covert-width) * 9 / 16 );
}
.featuredBox:last-child{
    margin-right: unset;
}
.featuredBoxInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 2;
}
.featuredNickRow{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
    text-decoration: none;
    pointer-events: fill;
}
.featuredNick{
    width: var(--space-32);
    height:  var(--space-32);
    border-radius: var(--space-16);
    margin-right: 12px;
}
.featuredNickname{
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}
.featuredTitleContainer{
    display: flex;
    flex-direction: column;
}
.featuredProductName{
    display: inline-block;
    margin: 12px 16px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
.featuredTitle{
    display: block;
    padding: 0 16px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1
}

@media only screen and (max-width: 1200px){
    .pageContainer {
        --covert-width: calc((100vw - 32px) / 2);
    }
    .adBannerContainer{
        top: 56px;
    }
    .firstEventImg,.firstEventImg>img{
        height: calc(100vw * 375 / 1200);
    }
    .featuredContainer{
        width: calc(100vw - 48px);
    }
    .moreFeatured{
        grid-template-columns: 1fr 1fr;
    }
    .infoContainer {
        width: calc(100% - 64px);
        padding-left: 32px;
        padding-right: 32px;
    }
    .detailContainer{
        width: auto;
        padding: 48px 32px;
    }
}

@media only screen and (max-width: 768px){
    .pageContainer {
        --covert-width: calc(100vw - 48px);
    }

    /*banner小广告栏*/
    .adBannerContainer{
        width: calc(100% - 48px);
        padding: 12px 24px;
        text-align: center;
        top: 56px;
    }

    /*Event*/
    .eventContainer{
        margin:unset;
        margin-top: 40px;
    }
    .firstEvent{
        width: auto;
        padding-left: unset;
        padding-right: unset;
    }
    .firstEventImg,.firstEventImg>img{
        height: 210px;
    }
    .infoContainer{
        flex-direction: column;
        width: calc(100% - 48px);
        padding-left: 24px;
        padding-right: 24px;
        margin-top: 32px;
    }
    /**项目标题**/
    .infoTextTitle{
        font-size: 32px;
        padding-right: 0;
    }
    .infoText,.infoTimeline{
        width: 100%;
        margin-bottom: 8px;
    }
    .rowLineBigTitle{
        font-size: 24px;
    }
    .rowLine2{
        margin-top: 0;
        padding-top: 16px;
    }
    .infoTextApply{
        width: 100%;
        margin-top: 24px;
    }
    .detailContainer{
        width: auto;
        padding: 48px 24px;
    }

    .btnApply{
        width: auto;
        margin-bottom: 24px;
        margin-left: var(--space-16);
        margin-right: var(--space-16);
    }
    /*背景遮罩*/
    .applyFormOutContainer{
        height: 100%;
        width: 100%;
    }
    /*表单申请容器*/
    .applyFormContainer{
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 24px;
        margin: 0 16px;
        gap: unset;
        isolation: isolate;
        width: calc(100% - 80px);
        height: calc(100% - 164px);
        position: fixed;
        left: unset;
        top: 50px;
        overflow-y: scroll;
    }
    .applyFormRow{
        flex-direction: column;
        gap: unset;
    }
    .applyFormRow input[type="text"],.applyFormRow input[type="email"],.applyFormRow input[type="url"]{
        -webkit-appearance:none;/*去除阴影边框*/
    }
    .applyFormColumn{
        gap: unset;
    }
    .applyFormColumnTitle{
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .applyFormColumnInput2 label{
        margin-right: 16px;
    }
    /**表单提交按钮**/
    .applyFormSubmitBtn{
        width: calc(100% - 32px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
    }


    /*用户回片Featured*/
    .featuredContainer{
        margin:34px auto;
    }
    .featuredBigTitleContainer{
        padding-left: 0;
        padding-right: 0;
        margin-bottom: var(--space-16);
    }
    .featuredBigTitle{
        font-size: 24px;
    }
    .featuredBigMore{
        font-size: 16px;
        /*display: none;*/
    }
    .featuredBigMore2{
        display: flex;
    }
    .moreFeatured{
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
    }
    .featuredBox{
        width: 100%;
        margin-bottom: 16px;
    }
    .featuredTitle{
        white-space:unset;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2
    }

}