.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    background: #F4F7F9;
}

/**顶部大标题**/
.topContainer{
    display: flex;
    padding-top:64px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.topText{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    height: 62px;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
}

/**通用段落卡片容器**/
.cardContainer{
    display: flex;
    width: 100%;
    justify-content: center;
}
/**通用段落卡片灰色背景**/
.cardContainerBgGrey{
    background: #FFFFFF;
}
/**通用卡片内容容器**/
.cardTextContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 80px 0;
    gap: 80px;
}
/**通用卡片左边标题**/
.cardLeft{
    width: 40%;
    font-weight: 700;
    font-size: 32px;
    line-height: 130%;
    color: #2F3133;
}
/**通用卡片右边大容器**/
.cardRight{
    width: 60%;
}

/**第1段落**/
.cardRight1Container{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    /*gap: 24px;*/
}
.cardRight1Top{
    font-weight: 700;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
    margin-bottom: 24px;
}
.cardRight1Bottom{
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0;
    gap: 24px;
}
.cardRight1BottomContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}


.cardRight1BottomLeftRound{
    display: flex;
    width: 100%;
    justify-content: space-between;

}
.cardRight1BottomRightRound{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.cardRight1BottomTitle{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    transform: translateY(10px);
}
.cardRight1BottomContent{
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
    padding: 16px 24px 24px 24px;
}

/**第2段落**/
.cardRight2Container{
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0;*/
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
}
.cardRight2Row{
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: flex-start;*/
    /*padding: 0;*/
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
}
.dotContainer{
    display: flex;
    /*flex-direction: column;*/
    align-items: flex-start;
    /*padding: 0 0 6px;*/
    /*gap: 4px;*/
    margin-right: 16px;
}
.dotOuter{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 0;
    /*gap: 10px;*/
    width: 9px;
}
.dot1{
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #F6866F;
}
.dot2{
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #6FB3F6;
}
.dot3{
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #8DD258;
}
.dot4{
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #B39BF7;
}
.dot5{
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background: #FF9FEA;
}
.vLine1{
    width: 1px;
    height: 100%;
    background: rgba(46, 50, 56, 0.09);
    margin-left: 4px;
}
.vLine2{
    display: flex;
    width: 1px;
    height: 23px;
    background: rgba(46, 50, 56, 0.09);
}
.vLine3{
    display: flex;
    width: 1px;
    height: 80px;
    background: rgba(46, 50, 56, 0.09);
}
.vLine4{
    display: flex;
    width: 1px;
    height: 80px;
    background: rgba(46, 50, 56, 0.09);
}
.vLine5{
    display: flex;
    width: 1px;
    height: auto;
    background: rgba(46, 50, 56, 0.09);
}
.cardRight2TimelineContainer{
    /*display: flex;*/
    /*align-items: flex-start;*/
    display: grid;
    grid-template-columns: 1px auto;
}
.cardRight2TimelineTitle{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    margin-left: 16px;
}
.cardRight2TimelineContent{
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
    margin-left: 24px;
}

/**第3段落**/
.cardRight3Container{
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
}

/**第4段落**/
.cardRight4Container{
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
}

/**第5段落**/
.cardRight5Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
}
.cardRight5Row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 16px;
}
.cardRight5RowSvg{
    width: 24px;
    height: 24px;
}
.cardRight5RowContent{
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 170%;
    color: #2F3133;
}

@media only screen and (max-width:1200px){
    /**顶部大标题**/
    .topContainer {
        padding: 32px 32px 0;
        width: calc(100% - 64px);
    }
    .cardRight1Bottom{
        grid-template-columns: unset;
    }
    .cardContainer{
        width: calc(100% - 64px);
        margin: auto;
        flex-direction: column;
        padding: 32px;
    }

}

@media only screen and (max-width: 768px){
    .pageContainer{
        background: #F4F7F9;
    }
    /**顶部大标题**/
    .topContainer {
        padding: 32px 24px 0;
        width: calc(100% - 48px);
    }

    .topText{
        font-size: 32px;
    }

    /**通用段落卡片容器**/
    .cardContainer{
        width: calc(100% - 48px);
        flex-direction: column;
        padding: 24px;
    }
    .cardContainer:last-child{
        margin-bottom: 48px;
    }
    /**通用卡片内容容器**/
    .cardTextContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding:0;
        /*padding-top: 24px;*/
        gap: 0;
    }
    /**通用卡片左边大容器**/
    .cardLeft{
        width: calc(100% - 48px);
        font-size: 24px;
        margin-bottom: 24px;
    }
    .cardRight2TimelineTitle{
        margin-bottom: 8px;
    }
    /*.cardRight2TimelineContent{*/
    /*    margin-bottom: 16px;*/
    /*}*/
    /**通用卡片右边大容器**/
    .cardRight{
        width: 100%;
    }
    .cardRight1Bottom{
        display: flex;
        flex-direction: column;
    }

}