.pageContainer{
    display: block;
    width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    --space-16:16px;
    --space-32:32px;
    /*background: linear-gradient(180deg, #FDE7E2 0%, rgba(253, 231, 226, 0) 100%);*/
    /*background-size:100% 600px;*/
    /*background-repeat: no-repeat;*/
    background: #F4F7F9;
    --covert-width: calc((1200px - 32px) / 3);
    --event-covert-width: calc((1200px - 32px) / 2);
}

.coverShade{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;
}

/*banner小广告栏*/
.adBannerContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F6866F;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    padding-top: 12px;
    padding-bottom: 12px;
}
.adBanner{
    color: #FFFFFF;
}
.arrow{
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
}

/**轮播广告容器**/
.wheelBannerContainer{
    width: 100%;
    max-width: var(--safe-width);
    height: 375px;
    background: #F4F7F9;
    margin: 24px auto 80px;
    /*overflow-x: hidden;*/
}
/*轮播左右箭头*/
.wheelBannerContainer:hover .leftArrow,.wheelBannerContainer:hover .rightArrow{
    display: block;
    width: 48px;
    height: 48px;
}

.wheelBannerContainer>.leftArrow,.wheelBannerContainer>.rightArrow{
    position: absolute;
    /*margin-top: calc(375px / 2);*/
    margin-top:164px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
}
.leftArrow{
    left: calc(100vw - var(--safe-width) - calc((100vw - var(--safe-width)) / 2) + 32px);
    background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-button-left.svg");
}
.leftArrow:hover{
    background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-button-left-hover.svg");
}
.rightArrow{
    left:calc(100vw - calc((100vw - var(--safe-width)) / 2) - 48px - 32px);
    background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-button-right.svg");
}
.rightArrow:hover{
    background-image: url("https://zhiyun-website-shenzhen.oss-cn-shenzhen.aliyuncs.com/explore/ui/explore-button-right-hover.svg");
}


/* SWIPER 轮播插件*/
:global(.swiper) {
    width: 100%;
    height: 100%;
}

:global(.swiper-slide) {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

:global(.swiper-slide img) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* SWIPER 轮播插件*/



.bannerImgBackground{
    text-decoration:none;
    /*cursor:pointer;*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 20% 60% 20%;
}

.bannerImgBackground>a{
    cursor: pointer;
}

.indicatorContainer{
    width: 100%;
    max-width: var(--safe-width);
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
}
.bulletClass{
    width: 6px;
    height: 6px;
    background: #DCE1E6;
    border-radius: 3px;
    margin-right: 12px;
}
.bulletActiveClass{
    width: 8px;
    height: 8px;
    background: #F6866F !important;
    border-radius: 4px;
    margin-right: 12px;
}

/*Events*/
.eventContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: auto;
}
.eventBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.eventBigTitle{
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    color: #000000;
}
.eventBigMore{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
.eventBigMore>svg{
    margin-left: 5px;
}

/*第一Event*/
.firstEvent{
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
}
.firstEventImg,.firstEventImg>img{
    display: block;
    width: 100%;
    height: 375px;
    position: relative;
}
/*整行Event*/
.moreEvent{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 48px;
}
.eventBox{
    text-decoration: none;
}
.eventBoxImg,.eventBoxImg>img{
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    /*aspect-ratio: 16 / 9;*/
    height: calc(var(--event-covert-width) * 9 / 16 );
}

.eventStatusBox{
    display: inline-block;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    right: 0;
    bottom: 0;
}

.eventStatusActive{
    background: #F6866F;
}

.eventStatusClose{
    background: rgba(94, 98, 102, 0.4);
    backdrop-filter: blur(20px);
}

/*标题时间*/
.eventTitleContainer{
    display: flex;
    flex-direction: column;
    padding: 18px 24px;
    /*background: linear-gradient(180deg, #F7FAFC 0%, #F5F7FA 0.01%, rgba(245, 247, 250, 0) 100%);*/
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}
.eventTitle{
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    margin-bottom: 8px;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
}
.eventDateTime{
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #C0C6CC;
}


/*Featured Works*/
.featuredContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: auto auto 64px;
}
.featuredBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.featuredBigTitle{
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    color: #000000;
}
.featuredBigMore{
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: right;
    color: #000000;
    text-decoration: none;
}
.featuredBigMore>svg{
    margin-left: 5px;
}

/*整行Featured*/
.moreFeatured{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}
.featuredBox{
    text-decoration: none;
    cursor: pointer;
}
.featuredBoxImg,.featuredBoxImg>a>img{
    display: block;
    width: 100%;
    position: relative;
    height: calc(var(--covert-width) * 9 / 16 );
}

.featuredBoxInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 2;
}
.featuredNickRow{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
    cursor: pointer;
    text-decoration: none;
    text-decoration: none;
    pointer-events: fill;
}
.featuredNick{
    width: var(--space-32);
    height:  var(--space-32);
    border-radius: var(--space-16);
    margin-right: 12px;
}
.featuredNickname{
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}
.featuredTitleContainer{
    display: flex;
    flex-direction: column;
}
.featuredProductName{
    display: inline-block;
    margin: 12px 16px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
.featuredTitle{
    /*display: flex;*/
    padding: 0px 16px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;
    width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 1200px) {
    .pageContainer {
        width: calc(100% - 64px);
        padding-right: 32px;
        padding-left: 32px;
        --covert-width: calc((100vw - 32px) / 2);
        --event-covert-width: calc(100vw / 2 - 32px);
    }
    .moreFeatured{
        grid-template-columns: 50% 50%;
    }
}

@media only screen and (max-width: 768px){
    .pageContainer {
        width: 100%;
        padding-right: unset;
        padding-left: unset;
        --covert-width: calc(100vw - 32px);
    }

    /*banner小广告栏*/
    .adBannerContainer{
        padding: 12px 24px;
        text-align: center;
    }

    /**轮播广告容器**/
    .wheelBannerContainer{
        height: 210px;
        margin: auto auto 54px;
    }

    .bannerImgBackground{
        grid-template-columns: 1% 98% 1%;
    }

    .leftArrow,.rightArrow{
        display: none !important;
    }

    .indicatorContainer{
        padding-top: 16px;
    }

    /*Event*/
    .eventBigTitleContainer{
        padding-left: var(--space-16);
        padding-right: var(--space-16);
        margin-bottom: 0;
    }
    .eventBigTitle{
        font-size: 24px;
    }
    .eventBigMore{
        font-size: 16px;
    }
    .firstEvent{
        display: none;
        width: calc(100% - 32px);
        padding: 0;
        margin: auto auto 16px;
    }
    .moreEvent{
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }
    .eventBox{
        width: calc(100% - 32px);
        padding: 0;
        margin: auto;
    }
    .eventBoxImg,.eventBoxImg>img{
        width: 100%;
    }

    /*Featured Works*/
    .featuredContainer{
        margin-bottom: 16px;
    }
    /*Featured*/
    .featuredBigTitleContainer{
        padding-left: var(--space-16);
        padding-right: var(--space-16);
        margin-bottom: var(--space-16);
    }
    .featuredBigTitle{
        font-size: 24px;
    }
    .featuredBigMore{
        font-size: 16px;
    }
    .moreFeatured{
        display: flex;
        flex-direction: column;
        grid-gap: unset;
    }
    .featuredBox{
        width: calc(100% - 32px);
        padding: 0;
        margin: auto auto 16px;
    }
    .featuredTitle{
        -webkit-line-clamp:2
    }

    /*.featuredBoxImg,.featuredBoxImg>a>img{*/
    /*    margin-bottom: 16px;*/
    /*}*/
}