.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --space-16:16px;
    --space-32:32px;
    background: #F4F7F9;
    --covert-width: calc((1200px - 32px) / 3);
}
.coverShade{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;
}
/**上部大背景**/
.pageContainerTopBackground{
    position: absolute;
    z-index: -100;
    width: 100vw;
    height: 600px;
}

/*Featured Works*/
.featuredContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: auto auto 34px;
}
.featuredBigTitleContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;;
    margin-bottom: 16px;
}
.featuredBigTitle{
    padding: 64px 0;
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
}
.featuredBigMore{
    font-weight: 800;
    font-size: 32px;
    line-height: 130%;
    text-align: right;
    color: #2F3133;
    text-decoration: none;
}
/*整行Featured*/
.moreFeatured{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
}
.featuredBox{
    text-decoration: none;
    cursor: pointer;
}
.featuredBoxImg,.featuredBoxImg>img{
    display: block;
    width: 100%;
    position: relative;
    height: calc(var(--covert-width) * 9 / 16 );
}
.featuredBox:last-child{
    margin-right: unset;
}
.featuredBoxInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}
.featuredNickRow{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-left: 16px;
}
.featuredNick{
    width: var(--space-32);
    height:  var(--space-32);
    border-radius: var(--space-16);
    margin-right: 12px;
}
.featuredNickname{
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
}
.featuredTitleContainer{
    display: flex;
    flex-direction: column;
}
.featuredProductName{
    display: inline-block;
    margin: 12px 16px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
    border: 1px solid rgba(255, 255, 255, 0.4);
}
.featuredTitle{
    display: block;
    padding: 0 16px 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #FFFFFF;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1
}

/**加载更加**/
.loadMore{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 369px;
    border: 1px solid #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: auto auto 80px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.loadMore:hover{
    background: #000000;
    color: #FFFFFF;
    transition: all 0.3s;
}
@media only screen and (max-width: 1200px) {
    .pageContainer {
        --covert-width: calc((100vw - 32px) / 2);
    }
    .moreFeatured{
        grid-template-columns: 1fr 1fr;
    }
    .bannerContentContainer{
        width: calc(100% - 64px)
    }
    .bannerContent{
        margin-bottom: 40px;
    }
    .featuredContainer{
        width: calc(100% - 64px);
        margin: auto;
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 1200px) {
    .moreFeatured{
        grid-template-columns: 1fr 1fr;
    }
    .bannerContentContainer{
        width: calc(100% - 64px)
    }
    .bannerContent{
        margin-bottom: 40px;
    }
    .featuredContainer{
        width: calc(100% - 64px);
        margin: auto;
        margin-bottom: 64px;
    }
}

@media only screen and (max-width: 768px){
    .pageContainer {
        --covert-width: calc(100vw - 32px);
    }

    .pageContainerTopBackground{
        z-index: 100;
        pointer-events:none;
    }
    /*Featured*/
    .featuredContainer{
        width: calc(100% - 32px);
        margin: auto auto 48px;
    }

    .featuredBigTitleContainer{
        /*padding-left: var(--space-16);*/
        /*padding-right: var(--space-16);*/
        margin-bottom: var(--space-16);
    }
    .featuredBigTitle{
        padding: 32px 0;
        font-size: 32px;
    }
    .featuredBigMore{
        font-size: 24px;
    }

    .moreFeatured{
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        grid-gap:0;
    }
    .featuredBox{
        width: 100%;
        margin-bottom: 16px;
    }

    /**加载更加**/
    .loadMore{
        width: calc(100% - 80px);
        padding: 20px;
        background: #000000;
        border: 1px solid #2F3133;
        color: #FFFFFF;
        margin: auto auto 32px;
    }
}