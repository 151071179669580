.pageContainer{
    display: block;
    width: 100vw;
    overflow-x: hidden;
    margin: 0 auto;
    --cardItem-width:256px;
    --cardItem-height:144px;
    background: #F4F7F9;
}

/**上部大背景**/
.pageContainerTopBackground{
    position: absolute;
    z-index: -100;
    width: 100vw;
    height: 600px;
}

/*Creators*/
.creatorContainer{
    display: flex;
    width: 100%;
    max-width: var(--safe-width);
    flex-direction: column;
    margin: 10px auto 80px;
}
.creatorBigTitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 64px;
    margin-top: 64px;
}
.creatorBigTitle{
    font-weight: 800;
    font-size: 48px;
    line-height: 130%;
    color: #000000;
}

/*************start PC****************/
/*卡片容器*/
.cardContainer{
    display: flex;
    flex-direction: column;
    gap: 32px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    padding: 24px;
    margin-bottom: 32px;
}
/*第一行*/
.cardFirstRowContainer{
    display: flex;
    height: 80px;
    gap: 24px;
    justify-content:space-between;
}
.cardFirstRowAvatarAndCountryContainer{
    display: flex;
    gap: 24px;
    align-items: center;
}
/*头像*/
.cardAvatar{
    height: 80px;
    width: 80px;
    border-radius: 40px;
}
.cardNicknameContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: start;
}
.cardNickname{
    display: flex;
    gap: 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    align-items: center;
}
.cardNickname>img{
    height: 20px;
    width: 20px;
}
.cardCountry{
    display: flex;
    gap: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    color: #8D9399;
    align-items: center
}
.cardCountry>img{
    width: 14px;
    height: 14px;
}
.cardPorfolioContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 4px;
    cursor: pointer;
    text-decoration: none;
}
.cardPorfolioText{
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: right;
    color: #000000;
}
.cardPorfolioArrow{
    height: 16px;
    width: 16px;
}
/*第二行，图片滚动*/
.cardSecondRowOutContainer{
    width: 100%;
    display: flex;
}

.cardSecondRowContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 8px;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
.cardSecondRowContainer::-webkit-scrollbar{
    display: none;
}

.cardSecondItem{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    isolation: isolate;
    width: var(--cardItem-width);
    height: var(--cardItem-height);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    text-decoration: none;
}
.cardSecondItemImg{
    width: var(--cardItem-width);
    height: var(--cardItem-height);
}
.coverShade{
    width: var(--cardItem-width);
    height: var(--cardItem-height);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
    padding-top: calc(var(--cardItem-height) - 25px);
    pointer-events: none;
}
.cardSecondItemTitle{
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1;
    padding: 0 16px;
}
.arrowLeft{
    display: none;
    position: absolute;
    width: 48px;
    height: 48px;
    margin-left: 16px;
    margin-top: calc(calc(var(--cardItem-height) - 48px) / 2);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
    border-radius: 80px;
    z-index: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.arrowLeft:hover{
    background: rgba(255, 255, 255, 0.6);
    transition: all 0.3s;
}
.arrowRight{
    display: none;
    position: absolute;
    width: 48px;
    height: 48px;
    margin-left: calc(100% - 112px);
    margin-top: calc(calc(var(--cardItem-height) - 48px) / 2);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
    border-radius: 80px;
    z-index: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.arrowRight:hover{
    background: rgba(255, 255, 255, 0.6);
    transition: all 0.3s;
}
.arrowShow{
    display: flex;
}
.arrowHide{
    display: none;
}
.arrowLeft>img,.arrowRight>img{
    height:20px;
    width: 20px;
}
@media only screen and (min-width: 1200px){
    .arrowRight{
        margin-left: calc(var(--safe-width) - 112px);
    }
}
/*************end PC****************/

/*************start Mobile****************/
/*卡片容器*/
.cardContainerMobile{
    width:calc(100% - 48px);
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(90deg, #E7EEF6 0%, rgba(231, 238, 246, 0) 100%);*/
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0 24px;
}
.cardAvatarContainerMobile{
    height: 80px;
    width: 80px;
    position: absolute;
    transform: translateY(-40px);
    left: calc(50% - 40px);
}
.cardAvatarMobile{
    height: 80px;
    width: 80px;
    border-radius: 40px;
}
.cardNicknameOutContainerMobile{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 40px;
    padding-top: 12px;
}
.cardNicknameContainerMobile{
    display: flex;
    gap: 6px;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #2F3133;
    justify-content: center;
    align-items: center;
}
.cardNicknameContainerMobile>img{
    height: 20px;
    width: 20px;
}

.cardCountryContainerMobile{
    display: flex;
    /*gap: 4px;*/
    justify-content: center;
    align-items: center;
    ont-weight: 400;
    font-size: 14px;
    color: #8D9399;
}
.cardCountryContainerMobile>img{
    width: 14px;
    height: 14px;
    margin-right: 2px;
}

.cardSecondRowContainerMobile{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    margin-top: 16px;
}
.cardSecondRowContainerMobile::-webkit-scrollbar{
    display: none;
}

/*单个*/
.cardSecondSingleItemMobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    isolation: isolate;
    height: 184px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    text-decoration: none;
}
.cardSecondSingleItemTitleMobile{
    width: calc(100% - 48px);
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1;
    padding: 0 16px 10px;
}

/*两个*/
.cardSecondDoubleItemMobile{
    width: calc(50% - 4px);
    /*min-width: 160px;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    isolation: isolate;
    height: 90px;
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
}
.cardSecondDoubleItemTitleMobile{
    width: calc(100% - 16px);
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1;
    padding: 0 8px 4px;
}

/*多个*/
.cardSecondMultiItemMobile{
    width: 40%;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    isolation: isolate;
    height: 90px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
    text-decoration: none;
}
.cardSecondMultiItemTitleMobile{
    width: calc(100% - 16px);
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #FFFFFF;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp:1;
    padding: 0 8px 4px;
}

.cardViewMoreContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 4px;
    cursor: pointer;
    text-decoration: none;
}
.cardViewMoreText{
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
}
/*************end Mobile****************/

/**加载更加**/
.loadMore{
    padding-top: 20px;
    padding-bottom: 20px;
    width: 369px;
    border: 1px solid #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin: auto auto 80px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
}
.loadMore:hover{
    background: #000000;
    color: #FFFFFF;
    transition: all 0.3s;
}

@media only screen and (max-width: 1200px){
    .creatorBigTitleContainer{
        padding-left: 16px;
    }
}

@media only screen and (max-width: 768px){
    .coverShade{
        width: 100%;
        height: 90px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.8) 100%);
        padding-top: calc(100% - 10px);
    }
    .creatorContainer{
        display: flex;
        width: 100%;
        max-width: var(--safe-width);
        flex-direction: column;
        margin: auto;
    }
    .pageContainerTopBackground{
        z-index: 100;
        pointer-events:none;
    }
    .creatorBigTitleContainer{
        margin: 32px 0;
    }
    .creatorBigTitle{
        font-size: 32px;
    }

    /**加载更加**/
    .loadMore{
        width: calc(100% - 72px);
        padding: 20px;
        background: #000000;
        border: 1px solid #2F3133;
        color: #FFFFFF;
        margin: auto auto 32px;
    }
}